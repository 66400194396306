import { RouteRecordRaw } from 'vue-router'
import UserLogin from '@/pages/UserLogin.vue'
import OtpConfirmation from '@/components/UserLogin/OtpConfirmation.vue'
import LoginOptions from '@/components/UserLogin/LoginOptions.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: UserLogin,
    name: '',
    children: [
      {
        path: '/',
        component: LoginOptions,
        name: 'login',
      },
      {
        path: '/otp',
        component: OtpConfirmation,
        name: 'otp-confirmation',
        props: true,
      },
    ],
  },
]

export default routes
