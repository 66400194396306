<template>
  <div class="w-full" data-testid="user-login">
    <div class="text-body-l mb-14 mt-2 text-center">{{ $t('login.headline') }}</div>

    <div v-for="oauthProvider in oauthProviders" :key="oauthProvider.provider" class="w-full">
      <form class="w-full" :action="oauthProvider.url" method="POST">
        <input type="hidden" name="authenticity_token" :value="authenticityToken" />

        <button type="submit" class="btn btn-text-black-l mb-2 flex h-[60px] w-full flex-row justify-center gap-0 border border-black">
          <img :src="oauthProvider.icon" />

          <div class="grow">
            {{ $t(`login.providers.${oauthProvider.provider}`) }}
          </div>
        </button>
      </form>
    </div>

    <hr class="my-10 w-full" />

    <form @submit.prevent="onSubmit">
      <TextInput name="email" :label="'E-Mail'" data-testid="otp-email" />

      <button class="btn-primary-black-l w-full" type="submit" :disabled="!meta.valid && meta.touched" data-testid="otp-submit-button">
        {{ $t('login.email_cta') }}
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import TextInput from 'omr-ui/lib/components/vee-validate-wrappers/TextInput.vue'
import { useNotificationStore } from '@/stores/notification'
import { useUserLoginStore } from '@/stores/login'
import authenticityToken from '@/helpers/authenticityToken'
import requestOtpCode from './requestOtpCode'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  oauthProvidersWithAuthorizePaths: unknown
  otpUrl: string
  isReviews: boolean
}>()

const router = useRouter()
const { t } = useI18n()
const { showError } = useNotificationStore()
const store = useUserLoginStore()

const schema = object().shape({
  email: string().email().required(),
})

const { meta, values, handleSubmit } = useForm({
  validationSchema: toTypedSchema(schema),
})

const onSubmit = handleSubmit(() => {
  requestOtpCode(props.otpUrl, values.email)
    .then((response) => response.json())
    .then((json) => {
      if (!json.success) {
        showError(json.error)
        return
      }

      store.setEmail(values.email)
      router.push({ name: 'otp-confirmation' })
    })
    .catch(() => showError(t('errors.generic')))
})

const oauthProviders = computed(() => {
  return Object.keys(props.oauthProvidersWithAuthorizePaths).map((provider) => ({
    provider,
    url: props.oauthProvidersWithAuthorizePaths[provider],
    icon: new URL(`/src/assets/oauth_providers/${provider}.svg`, import.meta.url).href,
  }))
})
</script>
