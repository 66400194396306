/* eslint-disable vue/one-component-per-file */
import { App, createApp } from 'vue'
import { i18n } from './locales/i18n'
import { DefaultApolloClient } from '@vue/apollo-composable'
import apolloClient from '@/services/apolloHttpClient'
import PageLoader from '@/PageLoader.vue'
import { createPinia } from 'pinia'
import createRouterForPath from '@/helpers/createRouterForPath'
import pageRoutes from '@/routes'
import * as pages from '@/pages'
import NotFound from '@/pages/NotFound.vue'

export default function initVueComponents() {
  // Find all DOM containers, and init
  document.querySelectorAll('.vue-component').forEach((domContainer: HTMLElement) => {
    if (domContainer.classList.contains('_js_inited')) {
      return
    }

    const { vueProps, ...otherProps } = domContainer.dataset

    const props = { ...JSON.parse(vueProps), ...otherProps }

    const path = props.path
    delete props.path

    let app: App

    if (!path) {
      // eslint-disable-next-line import/namespace
      const page = pages[props.name]

      app = createApp(page, { ...props })
        .use(i18n)
        .use(createPinia())
    } else {
      const routes = pageRoutes[props.name]
      const router = createRouterForPath(path, routes)
      router.addRoute({ path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound })

      app = createApp(PageLoader, { ...props })
        .use(i18n)
        .use(router)
        .use(createPinia())
    }

    app.provide(DefaultApolloClient, apolloClient)

    app.mount(domContainer)

    domContainer.classList.add('_js_inited')
  })
}
