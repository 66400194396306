<template>
  <CompanyDetailsForm
    v-if="meResult?.me && userPropertiesResult?.userPropertiesPreferences"
    :me="meResult.me"
    :user-properties-preferences="userPropertiesResult.userPropertiesPreferences"
  />
</template>

<script setup lang="ts">
import { useMeQuery, useUserPropertiesPreferencesQuery } from '@/gql/requests.generated'
import CompanyDetailsForm from './CompanyDetailsForm.vue'

const { result: userPropertiesResult } = useUserPropertiesPreferencesQuery({ fetchPolicy: 'cache-first' })
const { result: meResult } = useMeQuery({ fetchPolicy: 'cache-first' })
</script>
