<template>
  <div class="w-full">
    <div class="responsive-container pt-8">
      <span class="text-headline-l">{{ $t('invoices.title') }}</span>
      <p class="mt-4 max-w-[760px]">
        {{ $t('invoices.description_1') }}
      </p>
      <p class="mb-4 mt-4 max-w-[760px]" v-html="$t('invoices.description_2')"></p>
      <AlertBox v-if="hasUnpaidOrders" type="warning" class="mt-4 max-w-[760px]">
        <template #header>{{ $t('invoices.unpaidInvoices.alertTitle') }}</template>
        {{ $t('invoices.unpaidInvoices.alertDescription') }}
      </AlertBox>
      <div class="inline-flex gap-3">
        <SelectInput v-model="orderTypeSelect" :placeholder="$t('invoices.filters.orderType')" class="max-w-[150px]">
          <option value="BadgeOrder">{{ $t('invoices.badgeOrder') }}</option>
          <option value="TicketOrder">{{ $t('invoices.ticketOrder') }}</option>
        </SelectInput>

        <SelectInput v-model="eventSelect" class="max-w-[150px]" :placeholder="$t('invoices.filters.event')">
          <template v-for="event in availableEvents" :key="event.id">
            <option :value="event.slug">{{ event.name }}</option>
          </template>
        </SelectInput>
      </div>
    </div>
    <div v-if="myOrdersLoading" class="responsive-container">
      <CircleSpinner size="large" />
    </div>
    <div v-if="!myOrdersLoading && !ordersByYear.length" class="responsive-container mt-4">
      <h4>{{ $t('invoices.noInvoices.title') }}</h4>
      <p>{{ $t('invoices.noInvoices.description') }}</p>
    </div>
    <div v-if="ordersByYear && !myOrdersLoading" class="responsive-container text-left">
      <div class="w-full laptop:w-8/12 laptop:max-w-[858px]">
        <div v-for="yearGroup in ordersByYear" :key="yearGroup.year">
          <span class="text-headline-s mb-1 block">{{ yearGroup.year }}</span>
          <hr class="mb-4 h-[2px] border-0 bg-grey-200" />
          <div v-for="order in yearGroup.events" :key="order.id">
            <OrderItemDesktop class="hidden tablet:block" :order="order" />
            <OrderItemMobile class="tablet:hidden" :order="order" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { Ref } from 'vue'
import { useMyOrdersQuery, OrderAasmStateEnum, OrderTypeEnum } from '@/gql/requests.generated'
import AlertBox from '@/components/ui/AlertBox.vue'
import SelectInput from 'omr-ui/lib/components/SelectInput/SelectInput.vue'
import OrderItemDesktop from '@/components/MyInvoices/OrderItemDesktop.vue'
import OrderItemMobile from '@/components/MyInvoices/OrderItemMobile.vue'
import { OrderType, OrderEventType } from '@/gql/types'
import CircleSpinner from 'omr-ui/lib/components/CircleSpinner/CircleSpinner.vue'

const HIDDEN_BADGE_ORDER_STATUSES = [OrderAasmStateEnum.CheckoutFailed]
const HIDDEN_TICKET_ORDER_STATUSES = [OrderAasmStateEnum.CheckoutFailed, OrderAasmStateEnum.Requested]

const shouldShowOrder = (order: OrderType) => {
  if (order.type == OrderTypeEnum.BadgeOrder && HIDDEN_BADGE_ORDER_STATUSES.includes(order.aasmState)) return false
  if (order.type == OrderTypeEnum.TicketOrder && HIDDEN_TICKET_ORDER_STATUSES.includes(order.aasmState)) return false
  if (!order.event) return false

  return true
}

const orderTypeSelect = ref<OrderTypeEnum>()
const eventSelect = ref<string>()

const {
  result: myOrdersResult,
  loading: myOrdersLoading,
  refetch,
} = useMyOrdersQuery({
  orderType: null,
  eventSlug: null,
})

const availableEventsSet = ref(false)

const availableEvents: Ref<Array<OrderEventType>> = ref([])

watch([orderTypeSelect, eventSelect], ([newType, newEvent]) => {
  refetch({
    orderType: newType || null,
    eventSlug: newEvent || null,
  })
})

const myOrders = computed(() => {
  const orders = myOrdersResult.value?.myOrders?.nodes

  if (!orders) return null

  return orders.filter((order: OrderType) => shouldShowOrder(order))
})

watch(myOrders, () => {
  if (!myOrders.value) return []
  if (availableEventsSet.value) return availableEvents.value

  const events = []

  const eventSlugs = new Set()
  myOrders.value.forEach((order: OrderType) => {
    if (!order.event) return
    const eventSlug = order.event.slug
    if (!eventSlugs.has(eventSlug)) {
      events.push(order.event)
      eventSlugs.add(eventSlug)
    }
  })

  availableEventsSet.value = true

  availableEvents.value = events
})

const hasUnpaidOrders = computed(() => {
  if (!myOrders.value) return false
  const unpaidOrders = myOrders.value.filter((order) => {
    return order.aasmState == 'requested' && order.type == 'BadgeOrder'
  })
  return !!unpaidOrders.length
})

const ordersByYear = computed(() => {
  if (!myOrders.value) return []

  const categorizedOrders = {}

  myOrders.value.forEach((order: OrderType) => {
    const year = order.createdAt.substring(0, 4)
    if (!categorizedOrders[year]) categorizedOrders[year] = []
    categorizedOrders[year].push(order)
  })
  return Object.keys(categorizedOrders)
    .map((year) => ({ year, events: categorizedOrders[year] }))
    .reverse()
})
</script>
