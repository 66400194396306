import authenticityToken from '@/helpers/authenticityToken'

export default async function requestOtpCode(url: string, email: string) {
  return await fetch(url, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'X-CSRF-Token': authenticityToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      user: {
        email: email,
      },
    }),
  })
}
