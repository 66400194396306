import { CompanyDepartmentEnum, CompanySizeEnum, JobLevelEnum } from '@/gql/schema.generated'
import { object, string } from 'yup'

export const companyDetailsSchema = object().shape({
  jobLevel: string().oneOf(Object.values(JobLevelEnum)).optional(),
  companyDepartment: string().oneOf(Object.values(CompanyDepartmentEnum)).optional(),
  companySize: string().oneOf(Object.values(CompanySizeEnum)).optional(),
  companyName: string().optional(),
  companyEmail: string().email().optional(),
  companyHomepage: string().optional(),
  companyPhone: string().optional(),
  companyIndustryId: string().optional(),
  companyFieldId: string().optional(),
  companyStreet: string().optional(),
  companyNumber: string().optional(),
  companyAdditionalAddress: string().optional(),
  companyZip: string().optional(),
  companyCity: string().optional(),
  companyCountry: string().optional(),
})
