import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { getLocale } from '../../locales/i18n'

const locale = getLocale() ?? 'de'

// HTTP connection to the API
const link = createUploadLink({
  // You should use an absolute URL here
  uri: `https://${import.meta.env.VITE_MYOMR_HOST}/graphql`,
  credentials: 'include',
  headers: { 'request-locale': locale, 'omr-service-name': 'myomr-frontend' },
  fetchOptions: {
    fetchPolicy: 'no-cache',
  },
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo http client
export const apolloHttpClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
})

export default apolloHttpClient
