const EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s.]{2,63}$/i

const isEmailEntered = () => {
  const form = document.querySelector('#otp-login-form')
  if (form) {
    const input = form.querySelector('#user_email') as HTMLInputElement
    const button = form.querySelector('button[type=submit]') as HTMLButtonElement
    button.disabled = !input.value.match(EMAIL_REGEX)
  }
}

const isSubmitting = () => {
  const form = document.querySelector('#otp-login-form')
  const button = form.querySelector('button[type=submit]') as HTMLButtonElement
  button.disabled = true
  button.innerHTML = '<span class="loading-indicator"></span>'
}

document.addEventListener('DOMContentLoaded', function () {
  const form = document.querySelector('#otp-login-form')
  if (form) {
    form.addEventListener('submit', isSubmitting, false)

    const input = form.querySelector('#user_email') as HTMLInputElement

    isEmailEntered()
    input.addEventListener('input', isEmailEntered, false)
  }
})
