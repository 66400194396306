import { RouteRecordRaw } from 'vue-router'
import MyAccount from '@/pages/MyAccount.vue'
import AccountDetailsDataProvider from '@/components/MyAccount/AccountDetailsDataProvider.vue'
import ManageInterests from '@/components/MyAccount/ManageInterests.vue'
import CompanyDetailsDataProvider from '@/components/MyAccount/CompanyDetailsDataProvider.vue'
import AccountSettings from '@/components/MyAccount/AccountSettings.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: MyAccount,
    children: [
      {
        path: '/',
        component: AccountDetailsDataProvider,
        name: 'account',
      },
      {
        path: '/interests',
        component: ManageInterests,
        name: 'manage-interests',
      },
      {
        path: '/company',
        component: CompanyDetailsDataProvider,
        name: 'company-info',
      },
      {
        path: '/settings',
        component: AccountSettings,
        name: 'settings',
      },
    ],
  },
]

export default routes
