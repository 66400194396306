import '../src/confetti'
import '../src/login'

import initVueComponents from '../initVueComponents'

document.addEventListener('DOMContentLoaded', () => {
  document.dispatchEvent(new Event('modern-js-loaded'))

  initVueComponents()
})
