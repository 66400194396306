import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useUserLoginStore = defineStore('userLogin', () => {
  const email = ref<string>()

  function setEmail(newEmail: string) {
    email.value = newEmail
  }
  return { email, setEmail }
})
