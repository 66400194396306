<template>
  <div class="w-full">
    <div class="responsive-container mx-auto mt-14 flex max-w-96 flex-col items-center tablet:mt-40">
      <img v-if="isReviews" src="@/assets/omr-reviews-logo.svg" class="mx-auto w-36" />
      <img v-else src="@/assets/omr-logo.svg" class="mx-auto w-36" />

      <RouterView v-slot="{ Component }">
        <component :is="Component" v-bind="$props" />
      </RouterView>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { RouterView } from 'vue-router'
import { useNotificationStore } from '@/stores/notification'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  oauthProvidersWithAuthorizePaths: unknown
  otpUrl: string
  userSessionUrl: string
  flashes: Record<string, string>
  isReviews: boolean
}>()

const { showError, showInfo, showSuccess } = useNotificationStore()

onMounted(() => {
  Object.keys(props.flashes).forEach((key) => {
    const message = props.flashes[key]

    switch (key) {
      case 'alert':
      case 'error':
        showError(message)
        break
      case 'success':
        showSuccess(message)
        break
      default:
        showInfo(message)
        break
    }
  })
})
</script>
