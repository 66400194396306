import { object, string, boolean } from 'yup'
import { SalutationTypeEnum } from '@/gql/schema.generated'

export const accountDetailsSchema = object().shape({
  salutation: string().oneOf(Object.values(SalutationTypeEnum)).required(),
  firstName: string().required(),
  lastName: string().required(),
  birthdate: string().required(),
  phone: string().optional(),
  email: string().email().required(),
  networkingAccepted: boolean().required(),
  omrMatchConsent: boolean().required(),
})
