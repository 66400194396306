import confetti from 'canvas-confetti'
import omr from './omr'

omr.confettiExplosion = () => {
  const count = 600

  addEventListener('DOMContentLoaded', () => {
    function fire(particleRatio, opts) {
      const o = {
        ...opts,
        colors: ['#8259FF', '#00ADAD', '#F2B200'],
        origin: { y: 0 },
        particleCount: Math.floor(count * particleRatio),
      }
      confetti({
        ...o,
        angle: 135,
        origin: { x: 1, y: 0.7 },
      })
      confetti({
        ...o,
        angle: 45,
        origin: { x: 0, y: 0.7 },
      })
    }
    setTimeout(function () {
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      })
      fire(0.2, {
        spread: 60,
      })
      fire(0.35, {
        spread: 100,
        decay: 0.91,
      })
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      })
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      })
    }, 500)
  })
}
