import { RouteRecordRaw } from 'vue-router'
import DashboardTickets from '@/pages/DashboardTickets.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: DashboardTickets,
    name: 'dashboard',
  },
]

export default routes
