export const useMyOrders = () => {
  function getStatusChipColor(aasmState: string): string {
    switch (aasmState) {
      case 'appointed':
        return 'chip bg-yellow'
      case 'canceled':
        return 'chip bg-error'
      case 'checked_out':
        return 'chip bg-mint-500'
      case 'paid':
        return 'chip bg-mint-500'
      default:
        return 'chip bg-grey'
    }
  }

  return {
    getStatusChipColor,
  }
}
