import dashboardRoutes from '@/routes/dashboard'
import myAccountRoutes from '@/routes/myAccount'
import myInvoicesRoutes from '@/routes/myInvoices'
import userLoginRoutes from '@/routes/userLogin'

const pages = {
  DashboardTickets: dashboardRoutes,
  MyInvoices: myInvoicesRoutes,
  MyAccount: myAccountRoutes,
  UserLogin: userLoginRoutes,
}

export default pages
