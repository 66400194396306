import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

export default function createRouterForPath(path: string, routes: RouteRecordRaw[]) {
  const router = createRouter({
    history: createWebHistory(path),
    scrollBehavior() {
      window.scrollTo({ top: 0 })
      const scrollMain = document.getElementById('main')
      scrollMain && (scrollMain.scrollTop = 0)
    },
    routes,
  })

  return router
}
