<template>
  <div v-if="result?.me" class="w-full">
    <section class="grid-container profile-box my-8 gap-y-8">
      <div class="col-span-12 flex items-center gap-4 tablet:col-span-6">
        <img :src="result.me.profileImage" class="h-[65px] w-[65px] rounded-full" />

        <div>
          <p>{{ $t('profile_box.greeting') }}</p>

          <h1 v-if="result.me.firstName">{{ result.me.firstName }}</h1>
        </div>
      </div>

      <div class="col-span-12 tablet:col-span-6">
        <h5 class="text-headline-xs flex items-center gap-1">
          {{ $t('profile_box.my_interests_headline') }}
          <a :href="editInterestsUrl" class="hover:text-purple-700">
            <MdiSvg :path="mdiPencilOutline" size="24" />
          </a>
        </h5>

        <div v-if="result.me.interests.length" class="mt-2 flex flex-wrap gap-2">
          <span
            v-for="interest in result.me.interests"
            :key="interest.slug"
            class="text-body-s-bold shrink-0 rounded border border-purple-700 bg-purple-700 px-3 py-[6px] text-white"
          >
            {{ interest.name }}
          </span>
        </div>

        <div v-else class="mt-2">{{ $t('profile_box.no_interests') }}</div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiPencilOutline } from '@mdi/js'
import { MdiSvg } from 'omr-ui/lib/components/MdiSvg'
import { useMeQuery } from '@/gql/requests.generated'

const { result } = useMeQuery({ fetchPolicy: 'cache-first' })
const { locale } = useI18n()

const editInterestsUrl = computed(() => {
  return `https://${import.meta.env.VITE_MYOMR_HOST}/${locale.value}/account/interests`
})
</script>
