<template>
  <SelectInput name="salutation" :label="$t('complete_profile.salutation')" :options="salutationOptions" />

  <NameFields />

  <div class="flex flex-nowrap gap-1">
    <SelectInput name="birth_day" :label="$t('complete_profile.day')" :options="dayOptions" />
    <SelectInput name="birth_month" :label="$t('complete_profile.month')" :options="monthOptions" />
    <SelectInput name="birth_year" :label="$t('complete_profile.year')" :options="yearOptions" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useUserPropertiesPreferencesQuery } from '@/gql/requests.generated'
import SelectInput from 'omr-ui/lib/components/MyOMR/TicketDetailsFieldset/SelectInput.vue'
import NameFields from './shared/NameFields.vue'

const MAX_YEARS_OLD = 90
const MIN_YEARS_OLD = 13

const { result } = useUserPropertiesPreferencesQuery({ fetchPolicy: 'cache-first' })

const salutationOptions = computed(() =>
  result.value?.userPropertiesPreferences.salutations.map((item) => ({ text: item.name, value: item.enum as string })),
)

const range = (start: number, stop: number) =>
  Array.from({ length: stop - start + 1 }, (_, i) => (start + i).toString()).map((i) => ({ text: i, value: i.padStart(2, '0') }))

const dayOptions = range(1, 31)
const monthOptions = range(1, 12)

const currentYear = new Date().getFullYear()
const yearOptions = range(currentYear - MAX_YEARS_OLD, currentYear - MIN_YEARS_OLD).reverse()
</script>
