import { object, string, boolean, StringSchema } from 'yup'
import { SalutationTypeEnum } from '@/gql/schema.generated'

const requiredWhenBusiness = <T extends StringSchema>(parentSchema: T) =>
  parentSchema.when('business', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  })

export const paymentInfoSchema = object().shape({
  business: boolean().required(),
  salutation: string().oneOf(Object.values(SalutationTypeEnum)).required(),
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
  company: requiredWhenBusiness(string()),
  address: string().required(),
  additionalAddress: string().optional(),
  zip: string().required(),
  city: string().required(),
  country: string().required(),
  vatNumber: string().optional(),
  customerReference: string().optional(),
})
