import { setLocale, addMethod, string } from 'yup'

import deLocales from './de.json'
import enLocales from './en.json'
import { createI18n } from 'vue-i18n'
import omrui_de from './omr-ui/omr-ui.de.json'
import omrui_en from './omr-ui/omr-ui.en.json'
import EMAIL_REGEX from '@/helpers/emailRegex'

export const getLocale = () => {
  return document.getElementsByTagName('HTML')[0].getAttribute('lang')
}

const deLocale = { ...deLocales, ...omrui_de }
const enLocale = { ...enLocales, ...omrui_en }

export const messages = { de: deLocale, en: enLocale }

export const i18n = createI18n({
  legacy: false,
  locale: getLocale() ?? 'en',
  fallbackLocale: 'en',
  messages,
  warnHtmlMessage: false,
})

const { t } = i18n.global

addMethod(string, 'email', function validateEmail(_message) {
  return this.matches(EMAIL_REGEX, {
    message: t('validations.email'),
    name: 'email',
    excludeEmptyString: true,
  })
})

setLocale({
  array: {
    min: t('validations.min'),
  },
  mixed: {
    required: t('validations.required'),
    oneOf: t('validations.required'),
  },
  string: {
    email: t('validations.email'),
    length: t('validations.length'),
  },
})
