<template>
  <div class="flex flex-col gap-3">
    <SelectInput name="ownership" :label="`${$t('ticket.fields.ownership.none')} *`" :options="ownerships" />

    <TicketDetailsFieldset :user-properties="userProperties" class="flex flex-col gap-3" />

    <div v-if="ticket.ticketCategory.requiresFfwdConsent" class="mt-2">
      <FormCheckBox size="medium" name="ffwdConsent">{{ $t('ticket.fields.ffwdConsent') }}</FormCheckBox>
    </div>

    <div class="mt-2">
      <FormCheckBox size="medium" name="omrMatchConsent">{{ $t('ticket.fields.omrMatchConsent') }}</FormCheckBox>
    </div>

    <div class="flex justify-end gap-4">
      <button class="btn-secondary-purple-m mt-6" type="button" @click="emit('closeForm')">{{ t('ticket.form.cancel') }}</button>

      <button class="btn-primary-purple-m mt-6 gap-2" :disabled="!meta.valid" type="button" @click="saveChanges">
        {{ t('ticket.form.save') }}
        <CircleSpinner v-if="loading" size="small" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { object, string } from 'yup'

import SelectInput from 'omr-ui/lib/components/MyOMR/TicketDetailsFieldset/SelectInput.vue'
import { TicketDetailsFieldset } from 'omr-ui/lib/components/MyOMR/TicketDetailsFieldset'
import FormCheckBox from 'omr-ui/lib/components/vee-validate-wrappers/CheckBox.vue'

import CircleSpinner from 'omr-ui/lib/components/CircleSpinner/CircleSpinner.vue'

import { useUpdateTicketMutation } from '@/gql/requests.generated'
import { DashboardTicket, UserPropertiesPreferences } from '@/gql/types'
import { schema } from './schema'
import { validateEmail } from './validateEmail'
import { TicketOwnershipTypeEnum } from '@/gql/schema.generated'
import { useNotificationStore } from '@/stores/notification'

const { showError, showSuccess } = useNotificationStore()

const emailUniquenessValidationDisabled = true // TODO: Add debounce

const { t } = useI18n()

const { mutate, onError, loading, onDone } = useUpdateTicketMutation()

const props = defineProps<{
  ticket: DashboardTicket
  userProperties: UserPropertiesPreferences
}>()

const emit = defineEmits(['closeForm'])

const ownerships = [
  { value: TicketOwnershipTypeEnum.Me, text: t('ticket.fields.ownership.me') },
  { value: TicketOwnershipTypeEnum.Other, text: t('ticket.fields.ownership.other') },
]

function saveChanges() {
  if (loading.value) return

  const attributes = {
    ...values,
    ownership: values.ownership as TicketOwnershipTypeEnum,
    companyIndustryId: values.companyIndustryId ? parseInt(values.companyIndustryId, 10) : null,
    companyFieldId: values.companyFieldId ? parseInt(values.companyFieldId, 10) : null,
  }
  mutate({ input: { ticketId: props.ticket.id, attributes } })
}

onError(() => {
  showError(t('errors.generic'))
})

onDone((result) => {
  if (result?.data?.updateTicket?.errors?.length) {
    showError(result.data.updateTicket.errors[0])
  } else {
    showSuccess(t('payment_info.form.saved'))
    emit('closeForm')
  }
})

const fullSchema = schema.concat(
  object().shape({
    email: string()
      .email()
      .required()
      .test('email-uniqueness-validation', t('ticket.emailHelpText'), async (value) => {
        if (emailUniquenessValidationDisabled) return true
        return await validateEmail(value, props.ticket.ticketCategory.event.id, props.ticket.id)
      }),
  }),
)

const { values, meta } = useForm({
  validationSchema: toTypedSchema(fullSchema),
  initialValues: formStateFromProps(),
})

function formStateFromProps() {
  return {
    ownership: props.ticket.ownership ?? undefined,
    email: props.ticket.email ?? undefined,
    salutation: props.ticket.salutation ?? undefined,
    firstName: props.ticket.firstName ?? undefined,
    lastName: props.ticket.lastName ?? undefined,
    phone: props.ticket.phone ?? undefined,
    country: props.ticket.country ?? undefined,
    jobLevel: props.ticket.jobLevel ?? undefined,
    companyDepartment: props.ticket.companyDepartment ?? undefined,
    companyFieldId: props.ticket.companyFieldId?.toString() ?? undefined,
    companyIndustryId: props.ticket.companyIndustryId?.toString() ?? undefined,
    companyName: props.ticket.companyName ?? undefined,
    companySize: props.ticket.companySize ?? undefined,
    companyType: props.ticket.companyType ?? undefined,
    position: props.ticket.position ?? undefined,
    omrMatchConsent: props.ticket.omrMatchConsent ?? false,
    ffwdConsent: props.ticket.ffwdConsent ?? false,
  }
}
</script>
