import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'

export const useNotificationStore = defineStore('notification', () => {
  type NotificationType = 'error' | 'warning' | 'success' | 'info'
  type NotificationEntry = { message: string; visible: boolean; type: NotificationType }

  const { t } = useI18n()

  const notificationList = ref<NotificationEntry[]>([])

  function showMessage(msg: string, type: NotificationType) {
    notificationList.value.push({
      message: msg,
      visible: true,
      type,
    })
  }

  function showDefaultError() {
    showMessage(t('general.error.title'), 'error')
  }
  function showError(msg: string) {
    showMessage(msg, 'error')
  }
  function showWarning(msg: string) {
    showMessage(msg, 'warning')
  }
  function showSuccess(msg: string) {
    showMessage(msg, 'success')
  }
  function showInfo(msg: string) {
    showMessage(msg, 'info')
  }

  return { notificationList, showDefaultError, showError, showWarning, showSuccess, showInfo }
})
