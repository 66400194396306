<template>
  <h3>{{ $t('my_account.settings.headline') }}</h3>

  <div class="mt-10 flex gap-4">
    <button
      v-for="provider in providers"
      :key="provider.provider"
      class="group flex w-40 flex-col items-center gap-4 rounded border px-4 py-10 text-center hover:border-purple-700 hover:text-black"
      :class="{ 'is-connected': provider.isConnected }"
      @click="handleProviderClick(provider)"
    >
      <img :src="provider.iconUrl" class="w-12" />

      <div class="text-headline-xs hidden text-purple-700 underline group-[.is-connected]:block">
        {{ $t('my_account.settings.disconnect') }}
      </div>
      <div class="text-headline-xs block text-purple-700 underline group-[.is-connected]:hidden">
        {{ $t('my_account.settings.connect') }}
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMeQuery, useDisconnectOauthProviderMutation } from '@/gql/requests.generated'
import { OauthProviderEnum } from '@/gql/schema.generated'
import { useNotificationStore } from '@/stores/notification'
import authenticityToken from '@/helpers/authenticityToken'

const { t, locale } = useI18n()
const { showError, showSuccess } = useNotificationStore()
const { result } = useMeQuery({ fetchPolicy: 'cache-first' })
const { mutate, onDone, onError } = useDisconnectOauthProviderMutation()

type Provider = {
  provider: OauthProviderEnum
  iconUrl: string
  isConnected: boolean
}

const providers = computed(() => {
  return Object.values(OauthProviderEnum).map((provider) => {
    const connectedProvider = result.value?.me.oauthProviders.find((p) => p === provider)

    const p: Provider = {
      provider,
      iconUrl: providerIconUrl(provider),
      isConnected: !!connectedProvider,
    }

    return p
  })
})

onDone(() => {
  showSuccess(t('messages.disconnected'))
})

onError(() => {
  showError(t('errors.generic'))
})

function handleProviderClick(provider: Provider) {
  if (provider.isConnected) {
    mutate({ provider: provider.provider })
  } else {
    const form = document.createElement('form')

    form.method = 'POST'
    form.action = `https://${import.meta.env.VITE_MYOMR_HOST}/users/auth/${provider.provider}?locale=${locale.value}`

    const authenticityTokenField = document.createElement('input')
    authenticityTokenField.value = authenticityToken
    authenticityTokenField.name = 'authenticity_token'
    form.appendChild(authenticityTokenField)

    document.body.appendChild(form)

    form.submit()
  }
}

function providerIconUrl(provider: string) {
  return new URL(`/src/assets/oauth_providers/${provider}.svg`, import.meta.url).href
}
</script>
