<template>
  <div class="sm:hidden mb-4 w-full overflow-hidden rounded rounded-s border px-4 py-2">
    <div class="flex w-full justify-between">
      <div class="flex flex-1">
        <div class="mb-1 inline-block px-[6px] py-[2px] pb-0" :class="getStatusChipColor(order.aasmState)">
          {{ $t(`invoices.aasmStates.${order.aasmState}`) }}
        </div>
        <span class="text-body-m-bold ml-2 inline-block h-5 overflow-hidden">{{ orderTitle }}</span>
      </div>
      <div class="inline-flex flex-initial items-center">
        <span class="text-body-m">€{{ order.amountInEurosGross }}</span>
        <MdiSvg
          v-if="order.invoices.length"
          :path="expanded ? mdiChevronUp : mdiChevronDown"
          :size="18"
          class="ml-1 cursor-pointer"
          @click="expanded = !expanded"
        />
      </div>
    </div>
    <a
      v-if="showPayButton"
      :href="`https://checkout.omr.com/${locale}/overview/${order.afterPaymentToken}`"
      class="btn-primary-purple-m mb-2 mt-2 w-full text-white"
      >{{ $t('invoices.unpaidInvoices.payCta') }}</a
    >
    <template v-if="expanded">
      <div v-for="invoice in order.invoices" :key="invoice.number" class="mt-3 rounded rounded-s border px-3">
        <table>
          <thead>
            <tr class="text-label-s border-b border-grey-300 font-bold text-grey-700">
              <th class="py-4 text-start">{{ $t('invoices.invoiceTable.invoice') }}</th>
              <th class="py-4 text-start">{{ invoice.number }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('invoices.invoiceTable.date') }}</td>
              <td>{{ $d(new Date(invoice.createdAt)) }}</td>
            </tr>
            <tr>
              <td>{{ $t('invoices.invoiceTable.invoiceType') }}</td>
              <td>
                {{
                  invoice.transactionType === 'credit'
                    ? $t('invoices.paymentMethods.credit')
                    : $t(`invoices.paymentMethods.${order.paymentMethod}`)
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <a :href="invoice.invoiceUrl" class="btn-secondary-black-m my-2 w-full">
          {{ $t('invoices.downloadInvoice') }}
          <MdiSvg :path="mdiTrayArrowDown" :size="16" />
        </a>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import { mdiChevronDown, mdiChevronUp, mdiTrayArrowDown } from '@mdi/js'
import { MdiSvg } from 'omr-ui/lib/components/MdiSvg'
import { useI18n } from 'vue-i18n'
import { OrderType } from '@/gql/types'
import { useMyOrders } from '@/composables/UseMyOrders'

const { getStatusChipColor } = useMyOrders()

const { t, locale } = useI18n()

const props = defineProps<{
  order: OrderType
}>()

const expanded = ref(false)
const showPayButton = computed(() => {
  return props.order.type == 'BadgeOrder' && props.order.aasmState == 'requested'
})

const orderTitle = computed(() => {
  if (props.order.type == 'BadgeOrder') return t('invoices.badgeOrderTitle', { orderTitle: props.order.event.name })
  return t('invoices.ticketOrderTitle', { orderTitle: props.order.event.name })
})
</script>
<style lang="css" scoped>
table,
table th,
table td {
  @apply border-b border-l-0 border-r-0 border-t-0 border-b-grey-300;
}

table thead th {
  @apply p-3 py-4 text-start text-[12px] font-bold leading-[1.5] text-grey-900;
}
</style>
