import { RouteRecordRaw } from 'vue-router'
import MyInvoices from '@/pages/MyInvoices.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: MyInvoices,
    name: 'invoices',
  },
]

export default routes
