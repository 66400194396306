<template>
  <h3>{{ $t('my_account.interests.headline') }}</h3>

  <div class="text-body-m mt-2">
    {{ $t('my_account.interests.text') }}
  </div>

  <div v-if="interestsResult?.interests?.nodes" class="mt-10 flex flex-wrap gap-4">
    <button
      v-for="interest in interestsResult.interests.nodes"
      :key="interest.id"
      class="text-body-s-bold shrink-0 rounded border px-3 py-[6px]"
      :class="isSelected(interest) ? 'border-purple-700 bg-purple-700 text-white' : 'border-purple-300 bg-purple-100 text-purple-700'"
      @click="(event) => handleInterestClick(interest, event)"
    >
      {{ interest.name }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { CollectionUpdateEnum, useInterestsQuery, useMeQuery, useUpdateInterestsMutation } from '@/gql/requests.generated'
import { InterestType } from '@/gql/types'

const { result: meResult } = useMeQuery({ fetchPolicy: 'cache-first' })
const { result: interestsResult } = useInterestsQuery({ fetchPolicy: 'cache-first' })
const { mutate } = useUpdateInterestsMutation()

function isSelected(interest: InterestType) {
  if (!meResult.value?.me?.interests) return false

  return meResult.value.me?.interests.find((i) => i.slug === interest.slug)
}

function handleInterestClick(interest: InterestType, event: Event) {
  const type = isSelected(interest) ? CollectionUpdateEnum.Delete : CollectionUpdateEnum.Add
  mutate({ updates: [{ type, interestId: interest.id }] })

  const target = event.target as HTMLElement
  target.blur()
}
</script>
