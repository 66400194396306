<template>
  <div class="fixed bottom-4 left-0 right-0 z-[60] mx-auto max-w-sm">
    <ToastMsg
      v-for="(noti, i) in notificationList"
      :key="i"
      class="mb-4"
      :type="noti.type"
      :visible="noti.visible"
      :timeout="HIDE_TIMEOUT"
      @hide="noti.visible = false"
    >
      {{ noti.message }}
    </ToastMsg>
  </div>
</template>

<script setup lang="ts">
import { ToastMsg } from 'omr-ui/lib/components/ToastMsg'

import { useNotificationStore } from '@/stores/notification'

const HIDE_TIMEOUT = 7000

const { notificationList } = useNotificationStore()
</script>
